<template>
  <div class="d-flex justify-space-between mx-auto footer-info mt-12">
    <div class="d-flex flex-column justify-center">
      <div class="d-flex">
        <div>
          <div>{{ loanOfficer.firstName }} {{ loanOfficer.lastName }}</div>
          <div>{{ loanOfficer.title }}</div>
          <div>NMLS {{ loanOfficer.nmls }}</div>
        </div>
      </div>
      <div class="d-flex">
        <div>
          <a :href="phoneNumberURI">{{ loanOfficer.phone }}</a>
        </div>
      </div>
      <div class="d-flex">
        <div>
          <a :href="{ emailTo }">{{ loanOfficer.email }}</a>
        </div>
      </div>
    </div>

    <div>
      <v-img src="~@/assets/rm-lead-capture-footer.png" aspect-ratio="1" max-width="300" min-width="200" />
    </div>

    <!-- <div class="d-flex flex-column justify-end">
      <div class="font-weight-bold mb-4">{{ loanOfficer.company.companyName }}</div>
      <div>{{ loanOfficer.company.state }}</div>
      <div>NMLS {{ loanOfficer.company.nmls }}</div>
    </div> -->
  </div>
</template>

<script>
export default {
  computed: {
    loanOfficer() {
      return this.$store.state.loanOfficer.info;
    },
    phoneNumberURI() {
      const URI = `tel:${this.loanOfficer.phone}`;
      return URI;
    },
    emailTo() {
      return `mailto:${this.loanOfficer.email}`;
    }
  }
};
</script>

<style scoped>
.footer-info {
  max-width: 900px;
}

.contact-info-key {
  width: 80px;
}
</style>
