<template>
  <v-card
    class="mx-auto mb-8 py-6 px-4 px-sm-10 d-flex flex-column justify-start align-center"
    elevation="3"
    ><CustomPropertValueAppreciationRatesTable />
    <v-sheet class="my-8" width="80%" min-width="300" max-width="1000"
      ><NowVsFutureTableCDE
        finalRowLabel="Equity Change And Net Cash Flow Impact"
        preFinalRowLabel="Estimated Cash Flow Savings"
        :preFinalRowValue="estimatedTotalCashFlowSavings"
    /></v-sheet>

    <div class="clauseText--text medium-small-text-font mb-8 align-self-start">
      Worried about how much equity you might lose over the next
      {{ otherValues.lifeExpectancy }} years?
    </div>
    <div class="small-text-font align-self-center align-self-sm-start mb-4">
      Your equity right now is approximately
      <span class="font-weight-bold">{{ formatMoney(otherValues.homeEquity) }}</span
      >. If your property value increases by approximately
      <span class="font-weight-bold">{{
        formatPercentage(otherValues.calculatedAppreciationRate)
      }}</span>
      each year for the next
      <span class="font-weight-bold">{{ otherValues.lifeExpectancy }}</span> years, your equity in
      <span class="font-weight-bold">{{ otherValues.lifeExpectancy }}</span> years will be about the
      same as it is now:
      <span class="font-weight-bold">{{ formatMoney(otherValues.futureEquity) }}</span
      >.
    </div>
    <div class="small-text-font align-self-center align-self-sm-start mb-8">
      The average appreciation rate of every
      <span class="font-weight-bold">{{ otherValues.lifeExpectancy }}</span> year segment over the
      last 35+ years, based on the Case Schiller index shown below, has been
      <span class="font-weight-bold">{{ formatPercentage(averageAnnualAppreciationRate) }}</span>
    </div>
    <v-sheet width="80%" min-width="300" max-width="1000"
      ><CaseSchillerPropertyValueIndexChart :customBackground="false"
    /></v-sheet>
    <v-sheet width="80%" min-width="300" max-width="1000"> <CashFlowForCDEscenarios /></v-sheet>

    <!-- <AuditButton v-if="!isResultSaved" /> -->
  </v-card>
</template>

<script>
import CaseSchillerPropertyValueIndexChart from '../Charts/CaseSchillerPropertyValueIndexChart.vue';
import NowVsFutureTableCDE from '../Tables/NowVsFutureTableCDE.vue';
// import AuditButton from '../AuditButton.vue';
import CashFlowForCDEscenarios from '../Charts/CashFlowForCDEscenarios.vue';
import CustomPropertValueAppreciationRatesTable from '../Tables/CustomPropertValueAppreciationRatesTable.vue';

import helpers from '../../helpers';

export default {
  components: {
    CaseSchillerPropertyValueIndexChart,
    NowVsFutureTableCDE,
    // AuditButton,
    CashFlowForCDEscenarios,
    CustomPropertValueAppreciationRatesTable
  },
  computed: {
    otherValues() {
      return this.$store.state.scenarioData.currentScenarioData.finalValues.otherValues;
    },
    averageAnnualAppreciationRate() {
      return this.$store.state.scenarioData.currentScenarioData.chartsData
        .caseSchillerPropertyValueIndex.minOfAverageAppreciation;
    },
    estimatedTotalCashFlowSavings() {
      return this.$store.state.scenarioData.currentScenarioData.finalValues.tableValues
        .estimatedTotalCashFlowSavings;
    }
  },
  methods: {
    formatMoney: helpers.formatMoney,
    formatPercentage: helpers.formatPercentage
  }
};
</script>

<style scoped></style>
