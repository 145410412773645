<template>
  <v-app>
    <Header v-if="isLoanOfficerLoaded" />
    <v-main style="overflow: hidden">
      <v-container class="mt-4 pa-4" style="max-width: 1600px">
        <router-view />
      </v-container>
    </v-main>
    <Footer v-if="isLoanOfficerLoaded" />
  </v-app>
</template>

<script>
import Header from '@/views/Home/Header.vue';
import Footer from '@/views/Home/Footer.vue';

export default {
  components: {
    Header,
    Footer
  },
  computed: {
    isLoanOfficerLoaded() {
      return this.$store.getters['loanOfficer/isLoanOfficerLoaded'];
    }
  }
};
</script>

<style>
.v-select .v-select__selections {
  line-height: 26px;
}
.v-application {
  background-color: #f5f5f5 !important;
}

html {
  overflow-y: auto;
}

.v-tabs:not(.v-tabs--vertical).v-tabs--right
  > .v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(.v-slide-group--has-affixes)
  .v-slide-group__next,
.v-tabs:not(.v-tabs--vertical):not(.v-tabs--right)
  > .v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(.v-slide-group--has-affixes)
  .v-slide-group__prev {
  display: none !important;
}
/* TODO check styles */
/* .v-text-field--outlined > .v-input__control > .v-input__slot {
  min-height: 40px !important;
  height: 40px;
} */

/* Fonts */
/* Charts labels fonts sizes are located in store/chartsFontsModule */
/* .v-input {
  font-size: 1.25rem !important;
} */
.apexcharts-legend-text {
  font-size: 24px !important;
  font-weight: 500 !important;
}
.v-tab {
  font-size: 1.25rem !important;
  max-width: fit-content !important;
}
.extra-small-text-font {
  font-size: 1.25rem !important;
  font-family: Roboto, sans-serif !important;
}
.small-text-font {
  font-size: 1.5rem !important;
  font-weight: 400;
  line-height: 1.5rem;
  letter-spacing: 0.03125em !important;
  font-family: Roboto, sans-serif !important;
}
.medium-small-text-font {
  font-size: 1.75rem !important;
  font-weight: 500;
  letter-spacing: 0.0125em !important;
}
.medium-text-font {
  font-size: 2rem !important;
  line-height: 2rem;
  font-family: Roboto, sans-serif !important;
  font-weight: 400;
  letter-spacing: normal !important;
}
.large-text-font {
  font-size: 2.5rem !important;
  line-height: 2.5rem;
  letter-spacing: 0.0073529412em !important;
}
.v-select.v-input--dense .v-select__selection--comma {
  color: #1867c0b3 !important;
  /* color: rgba(0, 0, 0, 1) !important; */
  font-weight: 500 !important;
  font-size: 1.5rem !important;
}
.theme--light.v-input,
.theme--light.v-input input,
.theme--light.v-input textarea {
  font-weight: 500 !important;
  font-size: 1.5rem !important;
}
.v-label {
  color: #1867c0b3 !important;
  /* color: rgba(0, 0, 0, 1) !important; */
  font-weight: 500 !important;
}

.flex1 {
  flex: 1;
}

.full-width {
  width: 100%;
}
</style>
